import PageSwitchType from '../Types/PageSwitchType';
import PageMessage from '../Assets/PageMessage';
import LoginForm from './LoginForm';
function Login(props: PageSwitchType) {
    return (
        <div className='flex flex-col items-center justify-center font-crimson text-green text-xl h-max md:w-1/2 w-full'>
            <PageMessage message="Welcome back! If you've been here before, just enter your email to continue. I’d love to have you explore again!" />
            <LoginForm pageset={props.pageset} login={props.login} />
        </div>
    );
}

export default Login;