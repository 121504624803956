
import { useState } from 'react';
import Login from './Login/Login';
import Registration from './Registration/Registration';

import './App.css';

function App() {
  const [login, setlogin] = useState(true);
  function pageset(){
    setlogin(!login);
  }
  return (
    <div className="flex bg-[radial-gradient(circle,#000608,#39DFAA)] justify-center items-center h-screen w-screen">
      <div className="flex flex-col justify-center items-center md:h-max md:w-3/5 h-max w-3/4 rounded-lg overflow-hidden shadow-white-xl bg-black p-8">
        {login && <Login pageset={pageset} login={login} />}
        {!login && <Registration pageset={pageset} login={login} />}
      </div>
    </div>
  );
}

export default App;
