import Visitor from '../Types/Visitor';
function VisitorType(props: Visitor) {
    return (
        <label htmlFor={props.visitor.toLowerCase()} className='text-center border-2 cursor-pointer border-green gap-2 flex flex-col items-center justify-center text-lg rounded-md p-2 w-4/5 shadow-white-xl'>
            {props.visitor}
            <input id={props.visitor.toLowerCase()} value={props.visitor} checked={props.role === props.visitor} onChange={props.handler} type="radio" name="role" className='w-4/5 accent-green'></input>
        </label>
    )
}

export default VisitorType;