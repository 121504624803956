import PageSwitchType from '../Types/PageSwitchType';
import { useState, useEffect } from 'react';
import axios from "axios";
import { ToastContainer, toast } from 'react-toastify';
function LoginForm(props: PageSwitchType) {
    const [email, setEmail] = useState('');
    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setEmail(event.target.value);
    }
    useEffect(() => {
        checkReturning();
        return () => {
            console.log("Component is unmounted");
        };
    }, []);
    function checkReturning() {
        axios.get('https://api.ujjwaldevelops.com/v1/login/login', { withCredentials: true })
            .then(response => {
                if (response.status === 200) {
                    toast.success("Welcome Back!!!")
                    setTimeout(() => { window.location.href = 'https://ujjwaldevelops.com/' }, 300)
                }
            })
            .catch(error => {
            })
        return 'success';
    }
    function login() {
        if (!(email)) {
            toast.error("Please enter all the fields");
            return 'Error';
        }
        const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        console.log(emailRegex.test((email)));
        if (!emailRegex.test((email))) {
            toast.error("Please enter the email in correct format");
            return 'Error';
        }

        axios.get('https://api.ujjwaldevelops.com/v1/login/' + btoa(email), { withCredentials: true })
            .then(response => {
                if (response.status === 200) {
                    toast.success("You are in!!!")
                    setTimeout(() => { window.location.href = 'https://ujjwaldevelops.com/' }, 300)
                }
            })
            .catch(error => {
                if (error.status === 404) {
                    toast.error("Sorry! I can't find you why don't you recheck or register!!");
                    return 'Error'
                }
                else {
                    toast.error("Something went wrong");
                }
            })
        return 'success';
    }
    return (
        <div className='flex flex-col items-center justify-center gap-2 w-full h-2/5'>
            <ToastContainer toastStyle={{ backgroundColor: '#000608', color: '#39DFAA' }} progressStyle={{ backgroundColor: '#39DFAA' }} />
            <label htmlFor='email' className=''>Email</label>
            <input name='email' value={email} onChange={handleInputChange} id='email' className='bg-black border-2 border-green rounded-md text-lg p-1 focus:border-green focus:ring-2 focus:ring-green focus:outline-none'></input>
            <button onClick={login} className='p-1 rounded-lg border-2 border-green hover:bg-green hover:text-black transition duration-600 ease-in-ou shadow-white-xlt'>Get in</button>
            <span className='text-center text-sm pt-4'>New here? Let me know who you are! <button onClick={props.pageset} className='underline underline-offset-2 hover:underline-offset-4 hover:scale-105'>Register here</button> to tell me if you're a recruiter, a friend, or just exploring.</span>
        </div>
    )
}

export default LoginForm;