import PageSwitchType from '../Types/PageSwitchType';
import PageMessage from '../Assets/PageMessage';
import RegistrationForm from './RegistrationForm';
function Registration(props: PageSwitchType) {
    return (
        <div className='flex flex-col items-center justify-center font-crimson text-green text-xl h-full md:w-1/2 w-full pt-8'>
            <PageMessage message="Hi there! I'd love to know a bit about you. Are you a recruiter, a friend, or just exploring? Let’s get started with your name and email!" />
            <RegistrationForm pageset={props.pageset} login={props.login} />
        </div>
    );
}

export default Registration;