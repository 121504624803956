import { useState } from 'react';
import PageSwitchType from '../Types/PageSwitchType';
import VisitorType from '../Assets/VisitorType';
import axios from "axios";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
function RegistrationForm(props: PageSwitchType) {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [role, setRole] = useState('');
    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        console.log(event.target.name)
        if (event.target.name === 'name') {
            setName(event.target.value);
        }
        else if (event.target.name === 'email') {
            setEmail(event.target.value);
        }
        else if (event.target.name === 'role') {
            setRole(event.target.value); // Update role based on selected radio button
        }
    };
    function registration() {
        if(!(email && name && role)){
            toast.error("Please enter all the fields");
            return 'Error';
        }
        const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        console.log(emailRegex.test((email)));
        if(!emailRegex.test((email))){
            toast.error("Please enter the email in correct format");
            return 'Error';
        }
        const data = {
            name: name,
            recruiter: role,
            email: email
        };
        axios.post('https://api.ujjwaldevelops.com/v1/registration', data,{ withCredentials: true })
            .then(response => {
                if(response.status===200){
                    toast.success("You are in!!!")
                    setTimeout(()=>{window.location.href='https://ujjwaldevelops.com/'},300)
                }
            })
            .catch(error => {
                if(error.status===409){
                    toast.error("This Email Already exists please login using it");
                    return 'Error'
                }
                else{
                    toast.error("Something went wrong");
                }
            })
            return 'success';
    }
    return (
        <div className='flex flex-col items-center justify-center gap-2 w-full h-4/5'>
            <ToastContainer toastStyle={{backgroundColor: '#000608',color: '#39DFAA'}} progressStyle={{backgroundColor:'#39DFAA'}} />
            <label htmlFor='name' className=''>Name</label>
            <input name='name' value={name} onChange={handleInputChange} id='name' className='bg-black border-2 border-green rounded-md text-lg p-1 focus:border-green focus:ring-2 focus:ring-green focus:outline-none'></input>
            <label htmlFor='email' className=''>Email</label>
            <input name='email' value={email} onChange={handleInputChange} id='email' className='bg-black border-2 border-green rounded-md text-lg p-1 focus:border-green focus:ring-2 focus:ring-green focus:outline-none'></input>
            <div className='flex flex-row items-center justify-center gap-2 w-full pt-2'>
                <VisitorType role={role} handler={handleInputChange} visitor='Recruiter' />
                <VisitorType role={role} handler={handleInputChange} visitor='Friend' />
                <VisitorType role={role}  handler={handleInputChange} visitor='Casual Visitor' />
            </div>
            <button onClick={registration} className='p-1 rounded-lg border-2 border-green hover:bg-green hover:text-black transition duration-600 ease-in-out shadow-white-xl'>Submit</button>
            <span className='text-center text-sm pt-4'>Been here before? Just enter your email <button onClick={props.pageset} className='underline underline-offset-2 hover:underline-offset-4 hover:scale-105'>here</button> to let me know it's you and continue exploring! </span>
        </div>
    )
}

export default RegistrationForm;